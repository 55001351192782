import { useMutation } from '@tanstack/react-query'
import { signInAnonymously, getAuth } from 'firebase/auth'
import { getDatabase, push, ref, serverTimestamp } from 'firebase/database'
import { ref as storageRef, uploadBytes, getStorage } from 'firebase/storage'
import { useForm, object, snapshot } from '@kaliber/forms'
import { required, email, maxLength, minLength, min, max, optional } from '@kaliber/forms/validation'
import { useMediaQuery } from '@kaliber/use-media-query'

import { routeMap } from '/routeMap'
import { reportClientError } from '/machinery/reportClientError'
import { maxFileSize, minFileSize, fileExtension, checked, atsPhoneNumber, number, whitespace, noKLM } from '/machinery/customValidation'
import { useTranslate, useLanguage } from '/machinery/I18n'
import { useFirebaseApp } from '/machinery/FirebaseAppProvider'
import { useReportError } from '/machinery/ReportError'
import { pushToDataLayer, trackApplicationFinished, trackApplicationStarted } from '/machinery/tracking/pushToDataLayer'
import { useUserInfo } from '/machinery/UserInfoContext'
import { FORBIDDEN } from '/machinery/statusCodes'

import { Icon } from '/features/buildingBlocks/Icon'
import { LinkPrimary } from '/features/buildingBlocks/Link'
import { ButtonBlue, ButtonBlueReverse } from '/features/buildingBlocks/Button'
import { FormFieldText, FormFieldFile, FormFieldCheckbox, formFieldRenderers, FormFieldRadioGroup, FormFieldDropdown } from '/features/pageOnly/JobApplication/FormField'
import { BoardingPass } from '/features/pageOnly/JobApplication/BoardingPass'

import mediaStyles from '/cssGlobal/media.css'
import formFieldStyles from '/features/pageOnly/JobApplication/FormField.css'
import styles from './Form.css'

import paperPlane from '/images/icons/paper-plane.raw.svg'
import warningIcon from '/images/icons/warning-solid.raw.svg'

const validationPerFieldType = {
  NUMERIC: () => [number, min(0), max(99999)],
  TEXT: max => [minLength(2), maxLength(max)],
}

const acceptedFileTypes = ['pdf', 'docx']

export function Form({ job, step, handleStep, countries, layoutClassName = undefined }) {
  const { questions, recruiter, hiringManager, jobArea, id, isInternal, cvRequired = true } = job
  const language = useLanguage()
  const elementRef = React.useRef(null)
  const hasQuestions = Boolean(questions?.length)
  const questionsSorted = hasQuestions && sortQuestionsByOrder(questions)
  const firebaseApp = useFirebaseApp()
  const reportError = useReportError()
  const trackingHasStartedRef = React.useRef(false)
  const { claims } = useUserInfo()
  const { givenName, surName, employeeId, email: emailFromClaim } = claims || {}

  const { mutate, isPending, error } = useMutation({
    mutationFn: handleSendApplication,
    onError: e => {
      if (e.cause === 'kaliber') return
      reportError(e)
    },
    onSuccess: () => { trackApplicationFinished(job); handleStep(3) }
  })

  const stepOne = isInternal
    ? internalStepOneFormValues({ givenName, surName, employeeId, emailFromClaim })
    : externalStepOneFormValues()

  const emailValidation = isInternal
    ? [required, email, maxLength(100), whitespace]
    : [required, email, maxLength(100), whitespace, noKLM]

  const { form, submit } = useForm({
    formId: 'application',
    initialValues: {
      stepOne,
      ...(hasQuestions && {
        stepTwo: questionsInitialValues(questionsSorted),
      }),
      readPrivacyPolicy: '',
      declaredInformationIsTrue: false,
    },
    fields: {
      stepOne: object({
        givenName: [required, minLength(2), maxLength(100)],
        surname: [required, minLength(2), maxLength(100)],
        email: emailValidation,
        countryOfResidence: [required, minLength(2), maxLength(100)],
        phoneNumber: [required, whitespace, atsPhoneNumber, minLength(5), maxLength(100)],
        resume: [cvRequired ? required : optional, maxFileSize(10 * 1000 * 1000), minFileSize(0), fileExtension(acceptedFileTypes)],
        employeeId: [optional],
      }),
      ...(hasQuestions && {
        stepTwo: object(questionsFields(questionsSorted)),
      }),
      readPrivacyPolicy: [required, checked],
      declaredInformationIsTrue: [required, checked],
    },
    onSubmit: handleSubmit,
  })

  return (
    <div ref={elementRef} className={cx(styles.component, layoutClassName)}>
      <form data-x='job-application-form' className={styles.form} onSubmit={submit} onChange={(event) => handleCheckout(event, job)}>
        {step === 1 && (
          <StepOneFields form={form.fields.stepOne} hasRead={form.fields.readPrivacyPolicy} declaredTrue={form.fields.declaredInformationIsTrue} {...{ elementRef, handleStep, isPending, hasQuestions, cvRequired, countries, isInternal }} />
        )}
        {step === 2 && (
          <StepTwoFields form={form.fields.stepTwo} hasRead={form.fields.readPrivacyPolicy} declaredTrue={form.fields.declaredInformationIsTrue} {...{ handleStep, handleSubmit, isPending, error, hasQuestions, questionsSorted }} />
        )}
        {step === 3 && (
          <BoardingPass values={form.fields.stepOne.value.get()} layoutClassName={styles.boardingPassLayout} {...{ recruiter, hiringManager, jobArea, id }} />
        )}
      </form>
    </div>
  )

  function handleSubmit({ value, invalid }) {
    if (invalid) return

    const { stepOne, stepTwo, readPrivacyPolicy, declaredInformationIsTrue } = value
    const formValues = {
      ...stepOne,
      ...(stepTwo && { answers: answersToArray(stepTwo) }),
      readPrivacyPolicy,
      declaredInformationIsTrue,
      employeeId: stepOne.employeeId ? stepOne.employeeId : null
    }

    const { resume } = formValues
    const { files = null, storageRefs = null } = resume instanceof File
      ? {
        files: { resume },
        storageRefs: { resume: { extension: getExtension(resume.name) } }
      } : {}

    mutate({
      formValues,
      files,
      storageRefs,
    })
  }

  function handleCheckout(event, job) {
    const inputThreshold = event?.target?.value?.length > 2

    if (!trackingHasStartedRef.current && inputThreshold) {
      trackingHasStartedRef.current = true
      trackApplicationStarted(job)
    }
  }

  async function handleSendApplication({ formValues, files, storageRefs }) {
    await throwIfJobIsClosed(job)

    const { user: { uid } } = await signInAnonymously(getAuth(firebaseApp))

    if (files) await storeFiles({ uid, files })
    await push(ref(getDatabase(firebaseApp), 'services/application-processing-service/queue'), {
      formSubmitDate: serverTimestamp(),
      formValues,
      storageRefs,
      uid,
      language,
      jobId: id,
    })
  }

  async function storeFiles({ uid, files }) {
    try {
      const storage = getStorage(firebaseApp)
      await Promise.all(
        Object.entries(files).map(
          async ([fieldName, file]) => {
            if (!file) return

            const uploadRef = storageRef(storage, `/uploads/${uid}/${id}/${fieldName}.${getExtension(file.name)}`)
            await uploadBytes(uploadRef, file)
          }
        )
      )
    }
    catch (e) {
      reportClientError(e)
      throw new Error('upload-error', {
        cause: 'kaliber'
      })
    }
  }
}

function StepOneFields({ form, elementRef, handleStep, hasRead, declaredTrue, isPending, hasQuestions, cvRequired, countries, isInternal }) {
  const { __ } = useTranslate()

  return (
    <div className={styles.formFields}>
      <div className={styles.twoInputFields}>
        <FormFieldText
          field={form.fields.givenName}
          type='text'
          required
          label={__`application-form-field-givenName`}
          readOnly={isInternal}
        />
        <FormFieldText
          field={form.fields.surname}
          type='text'
          required
          label={__`application-form-field-surname`}
          readOnly={isInternal}
        />
      </div>
      <FormFieldText
        field={form.fields.email}
        type='text'
        required
        label={__`application-form-field-email`}
        readOnly={isInternal}
      />
      <FormFieldDropdown
        field={form.fields.countryOfResidence}
        type='text'
        options={countries}
        required
        label={__`application-form-field-country`}
      />
      <FormFieldText
        field={form.fields.phoneNumber}
        type='text'
        required
        label={__`application-form-field-phone`}
      />

      {cvRequired
        ? <FormFieldFile
          field={form.fields.resume}
          required
          placeholder={__`application-form-upload-cv`}
          label={__`application-form-field-cv`}
        />
        : <OptionalResume field={form.fields.resume} />
      }

      {!hasQuestions && <PrivacyStatement {...{ hasRead, declaredTrue }} />}

      <div className={styles.navigateContainer}>
        {hasQuestions ?
          <ButtonBlue onClick={handleValidation} label={__`next-step`} dataX='click-to-next-step' /> :
          <SendApplicationButton label={__`send`} {...{ isPending }} />
        }
      </div>
    </div>
  )

  function OptionalResume({ field }) {
    const [showResume, setShowResume] = React.useState(false)
    const { __ } = useTranslate()

    return (
      <div className={styles.componentOptionalResume}>
        <div className={styles.showResumeCheckbox}>
          <input type='checkbox' id='showResume' className={cx(formFieldStyles.inputFieldCheckbox, styles.checkboxLayout)} onChange={e => setShowResume(e.target.checked)} />
          <label htmlFor='showResume' className={formFieldStyles.labelCheckbox}>
            {__`application-form-field-optional-resume`}
          </label>
        </div>

        <div className={cx(styles.optionalResume, showResume && styles.showOptionalResume)}>
          <FormFieldFile
            placeholder={__`application-form-upload-cv`}
            label={__`application-form-field-cv`}
            {...{ field }}
          />
        </div>
      </div>
    )
  }

  function handleValidation() {
    if (snapshot.get(form).invalid) {
      const errors = snapshot.get(form).error.children
      Object.keys(errors).forEach(error => {
        form.fields[error].eventHandlers.onBlur()
      })
      return
    }

    window.scrollTo({ top: elementRef.current?.offsetTop / 2, behavior: 'smooth' })
    pushToDataLayer({ 'event': 'go_to_next_step' })
    handleStep(2)
  }
}

function StepTwoFields({ form, handleStep, isPending, hasRead, declaredTrue, hasQuestions, questionsSorted, error }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd) ?? false
  const { __ } = useTranslate()
  const errorMessage = error ? (error.cause === 'kaliber' ? __`${error.message}` : __`unknown-error`) : null

  return (
    <div className={styles.formFields}>
      {hasQuestions && questionsSorted.map(question => {
        const Component = formFieldRenderers[question.type]

        return (
          <Component
            key={question.id}
            field={form.fields[question.order]}
            options={question.answers}
            description={question.maxLength && __({ length: question.maxLength })`form-max-characters`}
            required={Boolean(question.required)}
            label={question.question}
            placeholder={__`application-form-select-default-placeholder`}
            layoutClassName={styles[question.type.toLowerCase()]}
          />
        )
      })}

      <PrivacyStatement {...{ hasRead, declaredTrue }} />

      {errorMessage && <FormErrorMessage {...{ errorMessage }} />}

      <div className={styles.navigateContainer}>
        <SendApplicationButton label={__`send`} {...{ isPending }} />
        <ButtonBlueReverse onClick={() => { handleStep(1) }} label={isViewportMd ? __`previous-step` : ''} dataX='click-to-previous-step' />
      </div>
    </div>
  )
}

export function FormErrorMessage({ errorMessage, layoutClassName = undefined }) {
  return (
    <p className={cx(styles.componentErrorMessage, layoutClassName)}>
      <Icon icon={warningIcon} layoutClassName={styles.iconLayout} />
      <span>{errorMessage}</span>
    </p>
  )
}

export function SendApplicationButton({ label, isPending }) {
  const { __ } = useTranslate()

  return (
    <button type='submit' disabled={isPending} className={styles.sendApplicationButton}>
      <div className={styles.sendIconContainer}>
        <Icon icon={paperPlane} layoutClassName={styles.sendIconLayout} />
      </div>
      <span className={styles.navigateSpan}>{isPending ? __`application-form-sending` : label}</span>
    </button>
  )
}

function sortQuestionsByOrder(questions) {
  return Object.values(questions).sort((a, b) => Number(a.order) - Number(b.order))
}

function questionsInitialValues(questions) {
  return questions.reduce((result, question) => ({ ...result, [question.order]: '' }), {})
}

function questionsFields(questions) {
  return questions.reduce(
    (result, question) => {
      const baseValidation = question.required ? [required] : []
      const maxLength = question.type === 'TEXT' ? Math.floor((question.maxLength ?? 1000) * 0.98) : null
      const extraRules = validationPerFieldType[question.type]
      const validation = baseValidation.concat(extraRules ? extraRules(maxLength) : [])

      return { ...result, [question.order]: validation }
    },
    {}
  )
}

function answersToArray(answers) {
  return Object.entries(answers).map(([id, value]) => ({ id, value }))
}

function getExtension(name) { return name.split('.').slice(-1).join() }

function PrivacyStatement({ hasRead, declaredTrue }) {
  const language = useLanguage()
  const { __ } = useTranslate()

  return (
    <div className={styles.componentPrivacyStatement}>
      <FormFieldRadioGroup
        field={hasRead}
        options={[
          { label: __`form-read-privacy-policy`, value: 'yes' },
          { label: __`form-did-not-read-privacy-policy`, value: 'no' },
        ]}
        required
      >
        {__`application-form-have-read-the-privacy-statement`}{' '}
        <LinkPrimary
          href={routeMap.app.privacy({ language })}
          label={__`privacy-statement`}
          dataX='link-to-data-privacy-statement'
          target='_blank'
        />
        {__`application-form-read`}
      </FormFieldRadioGroup>
      <FormFieldCheckbox
        field={declaredTrue}
        hasMultipleLines={true}
        required
        layoutClassName={styles.formFieldCheckboxLayout}
      >
        {__`application-form-accept-privacy-statement`}
      </FormFieldCheckbox>
    </div>
  )
}

function internalStepOneFormValues({ givenName, surName, emailFromClaim, employeeId }) {
  return {
    givenName: givenName,
    surname: surName,
    email: emailFromClaim,
    countryOfResidence: '',
    phoneNumber: '+31',
    resume: null,
    employeeId: employeeId,
  }
}

function externalStepOneFormValues() {
  return {
    givenName: '',
    surname: '',
    email: '',
    countryOfResidence: '',
    phoneNumber: '+31',
    resume: null,
  }
}

async function throwIfJobIsClosed({ id: jobId, language, kind }) {
  const endpoint = {
    INTERNAL: routeMap.api.v1.job.isOpen.internal(),
    PRIVATE_INTERNAL: routeMap.api.v1.job.isOpen.internal(),
    EXTERNAL: routeMap.api.v1.job.isOpen.external(),
    PRIVATE_EXTERNAL: routeMap.api.v1.job.isOpen.external(),
  }[kind]

  const response = await fetch(endpoint, {
    method: 'POST',
    body: JSON.stringify({
      language, kind, jobId
    }),
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  })

  if (response.status === FORBIDDEN) {
    throw new Error('not-allowed', { cause: 'kaliber' })
  } else if (!response.ok) {
    const responseText = await response.text()
    throw new Error(`Could not check if job ${jobId}/${kind}/${language} is open\n\n. ${response.status}: ${responseText}`)
  }

  const { isOpen } = await response.json()
  if (!isOpen) throw new Error('job-closed', { cause: 'kaliber' })

  return
}
